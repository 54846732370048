<template>
    <div class="bg-gray-100 rounded-md  sm:px-6 lg:px-8 sm:py-6 lg:py-6 ">
        <div class="bg-gray-100 rounded-md">
            <div >
                <div class="">
                    <div>
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            Integrations
                        </h3>
                        <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                            Enrich your community's activity
                        </p>
                    </div>
                    <div class="mt-6 sm:mt-5">
                        <div class="flex w-full flex-col md:col-span-2">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table class="min-w-full divide-y divide-gray-200">
                                            <thead>
                                            <tr>
                                                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Name
                                                </th>
                                                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Description
                                                </th>
                                                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Status
                                                </th>
                                                <th class="px-6 py-3 bg-gray-50"></th>
                                            </tr>
                                            </thead>
                                            <tbody class="bg-white divide-y divide-gray-200">
                                            <tr>
                                                <td class="px-6 py-4 whitespace-no-wrap">
                                                    <div class="flex items-center">
                                                        <div class="flex flex-shrink-0 h-10 w-10 items-center">
                                                            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2"></path></svg>                                                </div>
                                                        <div class="ml-4">
                                                            <div class="text-sm leading-5 font-medium text-gray-900">
                                                                Curriculum Board
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-no-wrap">
                                                    <div class="text-sm leading-5 text-gray-900">Curriculum for your learners</div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-no-wrap">
                                                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                                                                  :class="{'bg-blue-100 text-blue-800': integrationStatus('boards') === 'unintegrated',
                                                                  'bg-green-100 text-green-800': integrationStatus('boards') === 'active',
                                                                  'bg-red-100 text-red-800': integrationStatus('boards') === 'disabled'}">
                                                              {{ integrationStatus('boards') }}
                                                            </span>
                                                </td>
                                                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                                                    <button v-if="integrationStatus('boards') === 'unintegrated'" @click="addIntegration('boards')" class="text-indigo-600 hover:text-indigo-900 font-medium">Integrate</button>
                                                    <button v-if="integrationStatus('boards') === 'active'" @click="disableIntegration('boards')" class="text-indigo-600 hover:text-indigo-900 font-medium">disable</button>
                                                    <button v-if="integrationStatus('boards') === 'disabled'" @click="activateIntegration('boards')" class="text-indigo-600 hover:text-indigo-900 font-medium">activate</button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td class="px-6 py-4 whitespace-no-wrap">
                                                    <div class="flex items-center">
                                                        <div class="flex flex-shrink-0 h-10 w-10 items-center">
                                                            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"></path></svg>
                                                        </div>
                                                        <div class="ml-4">
                                                            <div class="text-sm leading-5 font-medium text-gray-900">
                                                                Discussion Forum
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-no-wrap">
                                                    <div class="text-sm leading-5 text-gray-900">A discussion space for questions and updates</div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-no-wrap">
                                                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                                                                  :class="{'bg-blue-100 text-blue-800': integrationStatus('forum') === 'unintegrated',
                                                                  'bg-green-100 text-green-800': integrationStatus('forum') === 'active',
                                                                  'bg-red-100 text-red-800': integrationStatus('forum') === 'disabled'}">
                                                              {{ integrationStatus('forum') }}
                                                            </span>
                                                </td>
                                                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                                                    <button v-if="integrationStatus('forum') === 'unintegrated'" @click="addIntegration('forum')" class="text-indigo-600 hover:text-indigo-900 font-medium">Integrate</button>
                                                    <button v-if="integrationStatus('forum') === 'active'" @click="disableIntegration('forum')" class="text-indigo-600 hover:text-indigo-900 font-medium">disable</button>
                                                    <button v-if="integrationStatus('forum') === 'disabled'" @click="activateIntegration('forum')" class="text-indigo-600 hover:text-indigo-900 font-medium">activate</button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td class="px-6 py-4 whitespace-no-wrap">
                                                    <div class="flex items-center">
                                                        <div class="flex flex-shrink-0 h-10 w-10 items-center">
                                                            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>                                                                </div>
                                                        <div class="ml-4">
                                                            <div class="text-sm leading-5 font-medium text-gray-900">
                                                                Group Sessions
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-no-wrap">
                                                    <div class="text-sm leading-5 text-gray-900">Live sessions with and for your community</div>
                                                </td>
                                                <td class="px-6 py-4 whitespace-no-wrap">
                                                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                                                                  :class="{'bg-blue-100 text-blue-800': integrationStatus('group-sessions') === 'unintegrated',
                                                                  'bg-green-100 text-green-800': integrationStatus('group-sessions') === 'active',
                                                                  'bg-red-100 text-red-800': integrationStatus('group-sessions') === 'disabled'}">
                                                              {{ integrationStatus('group-sessions') }}
                                                            </span>
                                                </td>
                                                <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                                                    <button v-if="integrationStatus('group-sessions') === 'unintegrated'" @click="addIntegration('group-sessions')" class="text-indigo-600 hover:text-indigo-900 font-medium">Integrate</button>
                                                    <button v-if="integrationStatus('group-sessions') === 'active'" @click="disableIntegration('group-sessions')" class="text-indigo-600 hover:text-indigo-900 font-medium">disable</button>
                                                    <button v-if="integrationStatus('group-sessions') === 'disabled'" @click="activateIntegration('group-sessions')" class="text-indigo-600 hover:text-indigo-900 font-medium">activate</button>
                                                </td>
                                            </tr>

                                            <!--                                                    -->
                                            <!--                                                    <tr>-->
                                            <!--                                                        <td class="px-6 py-4 whitespace-no-wrap">-->
                                            <!--                                                            <div class="flex items-center">-->
                                            <!--                                                                <div class="flex flex-shrink-0 h-10 w-10 items-center">-->
                                            <!--                                                                    <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z"></path></svg>-->
                                            <!--                                                                </div>-->
                                            <!--                                                                <div class="ml-4">-->
                                            <!--                                                                    <div class="text-sm leading-5 font-medium text-gray-900">-->
                                            <!--                                                                        Blog-->
                                            <!--                                                                    </div>-->
                                            <!--                                                                </div>-->
                                            <!--                                                            </div>-->
                                            <!--                                                        </td>-->
                                            <!--                                                        <td class="px-6 py-4 whitespace-no-wrap">-->
                                            <!--                                                            <div class="text-sm leading-5 text-gray-900">Write articles and posts</div>-->
                                            <!--                                                        </td>-->
                                            <!--                                                        <td class="px-6 py-4 whitespace-no-wrap">-->
                                            <!--                                                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">-->
                                            <!--                                                              Active-->
                                            <!--                                                            </span>-->
                                            <!--                                                        </td>-->
                                            <!--                                                        <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">-->
                                            <!--                                                            <a href="#" class="text-indigo-600 hover:text-indigo-900">Enable</a>-->
                                            <!--                                                        </td>-->
                                            <!--                                                    </tr>-->


                                            <!--                                                    <tr>-->
                                            <!--                                                        <td class="px-6 py-4 whitespace-no-wrap">-->
                                            <!--                                                            <div class="flex items-center">-->
                                            <!--                                                                <div class="flex flex-shrink-0 h-10 w-10 items-center">-->
                                            <!--                                                                    <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"></path></svg>-->
                                            <!--                                                                </div>-->
                                            <!--                                                                <div class="ml-4">-->
                                            <!--                                                                    <div class="text-sm leading-5 font-medium text-gray-900">-->
                                            <!--                                                                        Mailing List-->
                                            <!--                                                                    </div>-->
                                            <!--                                                                </div>-->
                                            <!--                                                            </div>-->
                                            <!--                                                        </td>-->
                                            <!--                                                        <td class="px-6 py-4 whitespace-no-wrap">-->
                                            <!--                                                            <div class="text-sm leading-5 text-gray-900">Reach your subscriber's email box</div>-->
                                            <!--                                                        </td>-->
                                            <!--                                                        <td class="px-6 py-4 whitespace-no-wrap">-->
                                            <!--                                                            <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">-->
                                            <!--                                                              Active-->
                                            <!--                                                            </span>-->
                                            <!--                                                        </td>-->
                                            <!--                                                        <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">-->
                                            <!--                                                            <a href="#" class="text-indigo-600 hover:text-indigo-900">Enable</a>-->
                                            <!--                                                        </td>-->
                                            <!--                                                    </tr>-->

                                            <!-- More rows... -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--                    <div class="mt-8 border-t border-gray-200 pt-5">-->
            <!--                        <div class="flex justify-end">-->
            <!--                            <span class="inline-flex rounded-md shadow-sm">-->
            <!--                                <button type="button" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">-->
            <!--                                    Cancel-->
            <!--                                </button>-->
            <!--                            </span>-->
            <!--                            <span class="ml-3 inline-flex rounded-md shadow-sm">-->
            <!--                                <button @click="createSession" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">-->
            <!--                                    Create-->
            <!--                                </button>-->
            <!--                            </span>-->
            <!--                        </div>-->
            <!--                    </div>-->
        </div>
    </div>
</template>

<script>
    import * as axios from "@/axios-auth";
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "CommunitySettingsIntegrations",
        props: {
            communityOwner: {
                type: String,
                required: true,
            },
            communityName: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters(['getCommunities', 'getUsername']),
            ...mapState({
                integrations: state => state.community.integrations
            }),
            fullCommunityName() {
                return `${this.communityOwner}/${this.communityName}`
            },
            hasAccess(){
                return (this.getCommunities.includes(this.fullCommunityName) || this.isOwner)
            },
            isOwner(){
                return (this.getUsername === this.communityOwner)
            }
        },
        methods: {
            integrationStatus(integrationName){
                if (this.integrations === null) {
                    return "unintegrated"
                }
                for (let integration of this.integrations) {
                    if (integrationName === integration.name) {
                        return integration.status
                    }
                }
                return "unintegrated"
            },
            addIntegration(integration){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.post(`/v2/community/integrations/${integration}/integrate/${this.communityOwner}/${this.communityName}`, {params: {q: this.communitySearchTerm}})
                .then((resp) => {
                    if(resp.status === 200 || resp.status === 201) {
                        this.addIntegrationLocally(resp.data)
                        console.log("integration received:", resp.data)
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                    loader.hide()
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: addIntegration FAILED", err)
                })
                // this.activateIntegrationLocally(integration)
            },
            disableIntegration(integration){
                this.disableIntegrationLocally(integration)
                axios.jigo.put(`/v2/community/disableIntegration/${this.communityOwner}/${this.communityName}/${integration}`, {params: {q: this.communitySearchTerm}})
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.error("ERROR: unwanted status returned", resp.status)
                        this.activateIntegrationLocally(integration)
                        this.showStatusAlertNotification = true
                        setTimeout(() => this.showStatusAlertNotification = false, 5000)
                    }
                })
                .catch((err) => {
                    this.activateIntegrationLocally(integration)
                    this.showStatusAlertNotification = true
                    setTimeout(() => this.showStatusAlertNotification = false, 5000)
                    console.error("ERROR: disableIntegration FAILED", err)
                })
                // this.disableIntegrationLocally(integration)
            },
            activateIntegration(integration){
                this.activateIntegrationLocally(integration)
                axios.jigo.put(`/v2/community/activateIntegration/${this.communityOwner}/${this.communityName}/${integration}`, {params: {q: this.communitySearchTerm}})
                .then((resp) => {
                    if(resp.status !== 200) {
                        console.error("ERROR: unwanted status returned", resp.status)
                        this.disableIntegrationLocally(integration)
                        this.showStatusAlertNotification = true
                        setTimeout(() => this.showStatusAlertNotification = false, 5000)
                    }
                })
                .catch((err) => {
                    this.disableIntegrationLocally(integration)
                    this.showStatusAlertNotification = true
                    setTimeout(() => this.showStatusAlertNotification = false, 5000)
                    console.error("ERROR: activateIntegration FAILED", err)
                })
                // this.activateIntegrationLocally(integration)
            },
            addIntegrationLocally(integration){
              this.$store.dispatch("addIntegration", integration)
            },
            activateIntegrationLocally(integration){
                this.$store.dispatch("activateIntegration", integration)
            },
            disableIntegrationLocally(integration){
                this.$store.dispatch("disableIntegration", integration)
            },
            formattedDate(dateString){
                let date = new Date(dateString)
                return date.toLocaleString()
            },
        }
    }
</script>

<style scoped>

</style>
